import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";
import { urlFor, client } from "../../client";
const Testimonial = () => {
  const [brands, setBrands] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClick = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const query = '*[_type=="testimonials"]';
    const brandsQuery = '*[_type=="brands"]';

    client.fetch(query).then((data) => {
      setTestimonials(data);
    });
    client.fetch(brandsQuery).then((data) => {
      setBrands(data);
    });
  }, []);

  const currentTestimonial = testimonials[currentIndex];
  return (
    <div
      id="testimonials"
      className="flex flex-col justify-center items-center text-customWhite py-10 px-20 "
    >
      <h2 className="font-semibold text-5xl mb-10 text-center">
        Some Happy Clients
      </h2>
      {testimonials.length && (
        <>
          <div className="shadow-lg flex flex-col items-center justify-center p-8 text-center border-2 border-tertiaryPurple border-opacity-50 rounded-lg ">
            <div className="max-w-2xl mx-auto mb-4 lg:mb-8">
              <p className="my-4 font-light">{currentTestimonial.feedback}</p>
            </div>

            <div className="flex items-center justify-center space-x-3">
              <img
                className="rounded-full w-20"
                src={urlFor(currentTestimonial.imageURL).url()}
                alt="testimonial"
              />
              <div className="space-y-0.5 font-medium text-left">
                <h4>{currentTestimonial.name}</h4>
                <h5 className="text-sm font-light text-customWhite text-opacity-50">
                  {currentTestimonial.company}
                </h5>
              </div>
            </div>
          </div>

          <div className="flex flex-row space-x-5 my-10">
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="border-2 rounded-full flex items-center justify-center border-tertiaryPurple cursor-pointer"
              onClick={() =>
                handleClick(
                  currentIndex === 0
                    ? testimonials.length - 1
                    : currentIndex - 1
                )
              }
            >
              <HiChevronLeft className="h-6 w-6 text-tertiaryPurple" />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.8 }}
              className="border-2 rounded-full flex items-center justify-center border-tertiaryPurple cursor-pointer"
              onClick={() =>
                handleClick(
                  currentIndex === testimonials.length - 1
                    ? 0
                    : currentIndex + 1
                )
              }
            >
              <HiChevronRight className="h-6 w-6 text-tertiaryPurple" />
            </motion.div>
          </div>
        </>
      )}
    </div>
  );
};

export default Testimonial;

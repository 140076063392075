import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client.js";

const About = () => {
  const [abouts, setAbouts] = useState([]);
  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client
      .fetch(query)
      .then((data) => setAbouts(data))
      .catch(console.error);
  }, []);
  return (
    <motion.div
      whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
    >
      <div
        id="about"
        className="px-20 flex flex-col justify-center items-center py-10 text-customWhite"
      >
        <h2 className="font-semibold text-5xl mb-10 text-center">
          What do I do?
        </h2>

        <div className="flex flex-col md:flex-row space-x-2 items-center justify-center">
          {abouts.map((about, index) => (
            <div
              key={about.title + index}
              className="justify-center items-center flex flex-col m-6 p-3 max-w-xs text-center"
            >
              <img
                className="w-48 rounded-3xl mb-5 grayscale invert"
                src={urlFor(about.imgUrl)}
                alt={about.title}
              />

              <div className="p-10 mt-5">
                <h2 className="p-3 font-semibold">{about.title}</h2>
                <p className="p-3 font-extralight">{about.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default About;

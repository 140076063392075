import React from "react";
import { About, Footer, Header, Skills, Testimonial, Work } from "./container";
import { Navbar } from "./components";
import { motion, useScroll, useSpring } from "framer-motion";

import "./app.scss";
const App = () => {
  return (
    <div className="bg-customBlack">
      <Navbar />
      <Header />
      <div className="spacer layer1"></div>
      <About />
      <Work />
      <Testimonial />
      <div className="spacer layer2"></div>
      <Footer />
    </div>
  );
};

export default App;

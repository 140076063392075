import React, { useState, useEffect } from "react";
import { FiMonitor } from "react-icons/fi";
import { FaCode } from "react-icons/fa";
import { motion } from "framer-motion";
import { urlFor, client } from "../../client";
const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [works, setWorks] = useState([]);
  const [filterWork, setFilterWork] = useState([]);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === "All") {
        setFilterWork(works);
      } else {
        setFilterWork(works.filter((work) => work.tags.includes(item)));
      }
    }, 500);
  };

  useEffect(() => {
    const query = '*[_type=="works"]';
    client.fetch(query).then((data) => {
      setWorks(data);
      setFilterWork(data);
    });
  }, []);
  return (
    <div id="work" className="text-customWhite py-10 px-6 md:px-12 lg:px-20">
      <motion.div
        whileInView={{ y: [100, 50, 0], opacity: [0, 0, 1] }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
      >
        <h2 className="text-4xl md:text-5xl font-semibold text-center mb-10">
          See what I've been doing recently
        </h2>
        <div className="flex flex-col md:flex-row justify-center items-center space-y-3 md:space-y-0 md:space-x-3">
          {["Web Development", "Game Development", "VR", "All"].map(
            (item, index) => (
              <div
                key={index}
                onClick={() => handleWorkFilter(item)}
                className={`p-2 rounded-lg cursor-pointer m-1 justify-center hover:border hover:border-primary hover:shadow-sm hover:shadow-primary ${
                  activeFilter === item
                    ? "border border-primary shadow-sm shadow-primary"
                    : ""
                }`}
              >
                <p className="font-medium">{item}</p>
              </div>
            )
          )}
        </div>

        <motion.div
          animate={animateCard}
          transition={{ duration: 0.5, delayChildren: 0.5 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 my-5 "
        >
          {filterWork.map((work, index) => (
            <motion.div
              whileHover={{ scale: 1.05 }}
              key={index}
              className="group relative block bg-customBlack row-auto"
            >
              <img
                className="absolute inset-0 h-full w-full object-cover opacity-50 transition-opacity group-hover:opacity-5"
                src={urlFor(work.imgUrl)}
                alt={work.name}
              />
              <div className="relative p-4 sm:p-6 lg:p-8">
                <h4 className="text-lg font-medium uppercase tracking-widest text-center mb-2">
                  {work.title}
                </h4>
                <div className="mt-11 sm:mt-12 lg:my-24">
                  <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                    <p className="mb-4 font-light text-center ">
                      {work.description}
                    </p>
                    <div className="flex text-center justify-center space-x-5">
                      {work.projectLink ? (
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          <a
                            href={work.projectLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FiMonitor className="text-2xl" />
                          </a>
                        </motion.div>
                      ) : null}

                      {work.codeLink ? (
                        <motion.div
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.8 }}
                        >
                          <a
                            href={work.codeLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <FaCode className="text-2xl" />
                          </a>
                        </motion.div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Work;

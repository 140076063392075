import React, { useState, useEffect } from "react";

import { images } from "../../constants";

import { motion } from "framer-motion";

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <nav className="bg-primary">
      <div className="container flex items-center justify-between mx-auto p-2">
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
          <a href="#home">
            <img src={images.logo} alt="Sumeet Logo" className="w-16" />
          </a>
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.8 }}>
          <a href="#contact" className="text-customWhite text-lg font-semibold">
            Contact Me
          </a>
        </motion.div>
      </div>
    </nav>
  );
};

export default Navbar;

import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: "m4e67irt",
  dataset: "production",
  useCdn: true,
  token:
    "skFJA8yqefbzyIkDiPrYWo2eawZvpoLdH1r2qDKMsYUyvcEsIHbrVpX5oETeFFYJ3OVzbHu0QDMXAIZ2vPa4RuHrMsPz1DmU4quTSoEXG7IS0Qn0oFhKD4cwVYyLCznD3K0fTTNdoeqH9dpuvpeFcEVOGZr8oQDBlD8wianTv28X41bUMYuP",
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);

import React from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
const Header = () => {
  return (
    <div
      id="home"
      className="bg-primary flex flex-col-reverse justify-center items-center py-10 px-10 md:px-20 2xl:px-96 2xl:py-44"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="flex flex-col justify-center py-10 px-20 text-customWhite text-center "
      >
        <h1 className=" font-normal text-xl pb-5">Hi, I'm Sumeet</h1>
        <p className="font-light text-3xl md:text-6xl md:leading-normal">
          From <span className="font-bold"> Web Applications </span> to
          <span className="font-bold"> Video Games</span>, I love to develop
          great user experiences.
        </p>
      </motion.div>
      <img
        src={images.profile}
        alt="profile"
        className="w-52 rounded-full shadow-lg"
      />
    </div>
  );
};

export default Header;

import React, { useState } from "react";
import { FaRegEnvelope, FaInstagram, FaLinkedin } from "react-icons/fa";

import { images } from "../../constants";

import { client } from "../../client";
import { motion } from "framer-motion";
const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { name, email, message } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: "contact",
      name: name,
      email: email,
      message: message,
    };

    client.create(contact).then(() => {
      setLoading(false);
      setIsFormSubmitted(true);
    });
  };
  return (
    <div
      id="contact"
      className="bg-primary px-20 flex flex-col justify-center items-center py-10 text-customWhite"
    >
      <h2 className="font-semibold text-5xl mb-2 text-center">
        Let's Create Something
      </h2>

      <div className="flex justify-around space-x-10 m-5">
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}
          className=""
        >
          <a href="mailto:sumeetgrg2001@gmail.com">
            <FaRegEnvelope size={25} />
          </a>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}
          className=""
        >
          <a href="https://www.instagram.com/sumeetgurung_dev/">
            <FaInstagram size={25} />
          </a>
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.8 }}
          className=""
        >
          <a href="https://www.linkedin.com/in/sumeetgurung/">
            <FaLinkedin size={25} />
          </a>
        </motion.div>
      </div>

      {!isFormSubmitted ? (
        <div className="flex flex-col py-6 space-y-6 md:py-0 md:px-6 w-1/2">
          <h3 className="font-semibold text-lg mt-5 mb-4 text-center">
            Send me a message!
          </h3>
          <div className="block">
            <label for="name" className="mb-1">
              Name
            </label>
            <input
              placeholder="First and Last Name"
              className="block text-customBlack p-2 w-full rounded-md shadow-sm focus:ring focus:ring-opacity-75 focus:ring-violet-400 focus:outline-none"
              type="text"
              name="name"
              value={name}
              onChange={handleChangeInput}
            />
          </div>
          <div className="block">
            <label for="email" className="mb-1">
              Email
            </label>
            <input
              placeholder="name@email.com"
              className="block text-customBlack p-2 w-full rounded-md shadow-sm focus:ring focus:ring-opacity-75 focus:ring-violet-400 focus:outline-none"
              type="email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div className="block">
            <label for="message" className="mb-1">
              Message
            </label>
            <textarea
              className="block min-h-max text-customBlack p-2 w-full rounded-md shadow-sm focus:ring focus:ring-opacity-75 focus:ring-violet-400 focus:outline-none"
              placeholder="Your Message"
              rows="3"
              value={message}
              name="message"
              onChange={handleChangeInput}
            ></textarea>
          </div>
          <motion.button
            type="button"
            className="border-2 shadow-md font-medium mx-auto p-2 border-customWhite rounded-lg"
            onClick={handleSubmit}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{ duration: 0.1 }}
          >
            {loading ? "Sending" : "Send Message"}
          </motion.button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch</h3>
        </div>
      )}
    </div>
  );
};

export default Footer;
